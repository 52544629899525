import React from "react"
import ReactDOM from "react-dom/client"
import App from "./App"
import { WalletProvider } from './WalletContext';

import "styles/main.scss"

const root = ReactDOM.createRoot(document.getElementById('mystriosplanet'));
root.render(
    <WalletProvider>
      <App />
    </WalletProvider>
);
