"use client"
import { useEffect, useRef } from "react"
import { gsap } from "gsap/dist/gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
import Styles from "styles/pages/Home.module.scss"
import Marquee from "react-fast-marquee"

const Earn = () => {
  const itemRefs = useRef([])
  const countainerRefs = useRef([])

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)

    gsap.set(itemRefs.current, { transformOrigin: "center",  translateX: "-400px", translateY: "400px" })

    gsap.to(itemRefs.current, {
      scrollTrigger: {
        trigger: countainerRefs.current,
        toggleActions: "restart none none none"
      },
      translateX: "+= 400px",
      translateY: "+= -400px",
      duration: 1.5,
      ease: "power2",
      onComplete: () => {
        gsap.to(itemRefs.current, {
          duration: 1.5,
          rotate: -0.5,
          translateY: "-10px",
          repeat: -1,
          yoyo: true,
          ease: "sine.inOut",
          delay: 0.2
        })
      }
    })
  }, [])

  const marqueeItems = ["PLAY", "TO", "EARN"]

  const repeatedItems = []
  const loopCount = 3 // Döngü sayısı

  for (let i = 0; i < loopCount; i++) {
    repeatedItems.push(...marqueeItems)
  }

  return (
    <div ref={countainerRefs} className={Styles["home-earn"]}>
      <div className={Styles["home-earn__list"]}>
        <Marquee>
          {repeatedItems.map((text, index) => (
            <span key={index}>{text}</span>
          ))}
        </Marquee>
      </div>
      <div ref={itemRefs} className={Styles["home-earn__avatar"]}>
        <img src="/images/characters/1.svg" alt="character" />
      </div>
    </div>
  )
}

export default Earn
