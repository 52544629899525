"use client"
import { useState } from "react"
import Styles from "styles/components/Modal.module.scss"
const Source = ({ logo, shorty, name, value, type, list, wallet, onOptionChange, onInputChange }) => {
  const [selectedOption, setSelectedOption] = useState(list ? list[0].shorty : "")
  const [inputValue, setInputValue] = useState(value)

  const handleOptionChange = (event) => {
    setInputValue(1)
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
    const selectedListItem = list.find((item) => item.shorty === selectedValue);
    const mysValue = selectedListItem ? selectedListItem.mysValue : null;
    const selectedShorty = selectedListItem ? selectedListItem.shorty : null;
    onOptionChange(selectedValue, mysValue);
  }

  const handleInputChange = (event) => {
    const newValue = event.target.value
    setInputValue(newValue)
    onInputChange(newValue)
  }

  return (
    <div className={Styles["modal-source"]}>
      <div className={Styles["modal-source__logo"]}>
        <img src={list ? list.find((item) => item.shorty === selectedOption).logo : logo} alt="source logo" />
      </div>
      <div className={Styles["modal-source__content"]}>
        <div className={Styles["modal-source__content-shorty"]}>
          {list ? list.find((item) => item.shorty === selectedOption).shorty : shorty}
        </div>
        <div className={Styles["modal-source__content-name"]}>
          {list ? list.find((item) => item.shorty === selectedOption).name : name}
        </div>
        {list && (
          <select value={selectedOption} onChange={handleOptionChange}>
            {list.map((item, index) => (
              <option key={index} value={item.shorty}>
                {item.shorty}
              </option>
            ))}
          </select>
        )}
      </div>
      <div className={Styles["modal-source__value"]}>
        {wallet ? <span>{value}</span> : <input min="1" type="number" value={inputValue} onChange={handleInputChange} />}
        <span>{type}</span>
      </div>
    </div>
  )
}

export default Source
