import React, { useState, useEffect, createContext } from 'react';
import { ethers } from "ethers";

export const WalletContext = createContext();

export const WalletProvider = props => {

    const [networkId, setNetworkId] = useState(0)
    const [accounts, setAccounts] = useState([])
    const [wallet, setWallet] = useState(null)

    const [networkStatus, setNetworkStatus] = useState(0)
    const [isSkale, setIsSkale] = useState(0)
    const [shortName, setShortName] = useState("ETH")
    const [mystOreAdd, setMystOreAdd] = useState("0x923468712f33e571c5B9a68684613668b00f2CA5")
    const [ethPrice, setEthPrice] = useState("0.0005")

    useEffect(() => {

        const handleMetaMaskChange = () => {
            window.location.href = "/";
        }

        const loadWeb3 = async () => {
            if (window.ethereum) {
                window.web3 = new ethers.providers.Web3Provider(window.ethereum)
                await window.ethereum.request({ method: 'eth_requestAccounts' });
                loadBlockchainData()
                await window.ethereum.on('message', () => window.location.reload());
                await window.ethereum.on('disconnect', handleMetaMaskChange);
                await window.ethereum.on('accountsChanged', handleMetaMaskChange);
                await window.ethereum.on('chainChanged', handleMetaMaskChange);
            }
            else if (window.web3) {
                window.web3 = new ethers.providers.Web3Provider(window.ethereum)
                loadBlockchainData()
                await window.ethereum.on('message', () => window.location.reload());
                await window.ethereum.on('disconnect', handleMetaMaskChange);
                await window.ethereum.on('accountsChanged', handleMetaMaskChange);
                await window.ethereum.on('chainChanged', handleMetaMaskChange);
            }
            else {
                // window.location.href = "/";
            }
        }

        const switchNetwork = (param) => {
            switch (param) {
                case 1482601649:
                    setNetworkStatus(1);
                    setIsSkale(1);
                    setShortName("MYSTRIUM");
                    setMystOreAdd("0x923468712f33e571c5B9a68684613668b00f2CA5");
                    setEthPrice("0.0005");
                    return
                case 1:
                    setNetworkStatus(1);
                    setIsSkale(0);
                    setShortName("ETH");
                    setMystOreAdd("0xdD13503b9657A111C0b41A3C3Bf113A11cEE30A4");
                    setEthPrice("0.0005");
                    return
                case 56:
                    setNetworkStatus(1);
                    setIsSkale(0);
                    setShortName("BNB");
                    setMystOreAdd("0x3309F6E5FD78bB9AFc78C15C85E12879c64FAeD9");
                    setEthPrice("0.003");
                    return
                case 43114:
                    setNetworkStatus(1);
                    setIsSkale(0);
                    setShortName("AVAX");
                    setMystOreAdd("0x923468712f33e571c5B9a68684613668b00f2CA5");
                    setEthPrice("0.1");
                    return
                case 137:
                    setNetworkStatus(1);
                    setIsSkale(0);
                    setShortName("MATIC");
                    setMystOreAdd("0xd640d4680935Dc972dCbFf067bc1BDAcDaDE6ef7");
                    setEthPrice("1.0");
                    return
                case 250:
                    setNetworkStatus(1);
                    setIsSkale(0);
                    setShortName("FTM");
                    setMystOreAdd("0x923468712f33e571c5B9a68684613668b00f2CA5");
                    setEthPrice("3.0");
                    return
                default:
                    setNetworkStatus(0);
                    return
            }
        }

        const loadBlockchainData = async () => {
            const provider = new ethers.providers.Web3Provider(window.ethereum)

            const accounts = await provider.send("eth_requestAccounts", []);

            const { chainId } = await provider.getNetwork()
            const { name } = await provider.getNetwork()

            const signer = provider.getSigner()
            let address = await signer.getAddress()

            switchNetwork(chainId)

            setAccounts(accounts)
            setWallet(accounts[0])
            setNetworkId(chainId)
        }

        loadWeb3()
    }, []);

    useEffect(() => {
        setWallet(accounts[0])
    }, [wallet])

    return (
        <WalletContext.Provider value={[wallet, networkId, networkStatus, isSkale, shortName, mystOreAdd, ethPrice]}>
            {props.children}
        </WalletContext.Provider>
    )
}