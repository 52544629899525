import Styles from "styles/pages/Home.module.scss"
import Icon from "components/Icon"
import Marquee from "react-fast-marquee"

const Bar = () => {
  const marqueeItems = [
    {
      text: "PLAY TO EARN",
      icon: "coin",
      className: Styles["home-bar__item"]
    },
    {
      text: "ZERO GAS FEES",
      icon: "smile",
      className: Styles["home-bar__item"] + " " + Styles["home-bar__item--yellow"]
    }
  ]

  const repeatedItems = []
  const loopCount = 8 // Döngü sayısı

  for (let i = 0; i < loopCount; i++) {
    repeatedItems.push(...marqueeItems)
  }

  return (
    <div className={Styles["home-bar"]}>
      <div className={Styles["home-bar__inner"]}>
        <Marquee>
          {repeatedItems.map((item, index) => (
            <div key={index} className={item.className}>
              <div className={Styles["home-bar__item-text"]}>{item.text}</div>
              <div className={Styles["home-bar__item-icon"]}>
                <Icon name={item.icon} />
              </div>
            </div>
          ))}
        </Marquee>
      </div>
    </div>
  )
}

export default Bar
