// components/common/Layouts.tsx
import GameLayout from "./GameLayout"
import LandingLayout from "./LandingLayout"

export const Layouts = {
  Landing: LandingLayout,
  Game: GameLayout
}

export default function Layout(props) {
  return (
    <>
      <Layouts.Landing wallet={props.wallet} networkId={props.networkId} networkStatus={props.networkStatus} isSkale={props.isSkale} shortName={props.shortName} mystOreAdd={props.mystOreAdd} ethPrice={props.ethPrice} />
      <Layouts.Game wallet={props.wallet} networkId={props.networkId} networkStatus={props.networkStatus} isSkale={props.isSkale} shortName={props.shortName} mystOreAdd={props.mystOreAdd} ethPrice={props.ethPrice} />
    </>
  )
}
