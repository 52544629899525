import Icon from "components/Icon"

const Button = ({ color, size, reverse, tag, href, disabled, icon, ...props }) => {
  const Tag = tag || (href ? "a" : "button")
  const className = `btn${size ? ` size-${size}` : " size-md"}${color ? ` color-${color}` : " color-primary"}${
    reverse ? " reverse" : ""
  }${disabled ? " disabled" : ""}`

  return (
    <Tag href={href} className={className} {...props}>
      {props.children}
      {icon && <Icon name={icon} />}
    </Tag>
  )
}

export default Button
