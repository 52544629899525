"use client"
import { useEffect, useRef } from "react"
import { gsap } from "gsap/dist/gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
import Styles from "styles/pages/Home.module.scss"
import Heading from "components/home/Heading"

const Faq = () => {
  const list = [
    {
      order: "01",
      title: "HOW CAN I PLAY?",
      text: "Mystrios is a socialization and Play 2 Earn GameFi application running on SKALE Network. By using the token called Mystrium Ore, it allows its users to chat with each other and make various purchases, NFT mints. You can connect to mystrios.com with your Metamask wallet, which you use as an add-on in your internet browser, or with your Metamask application on your mobile device."
    },
    {
      order: "02",
      title: "WHAT ABOUT NEW GAMES?",
      text: "On Mystrios.com you will find different games with single player or multiplayer. You can play all games with Game Ticket. Again, all your earnings will accumulate in the Reward Wallet."
    },
    {
      order: "03",
      title: "MYSTRIUM ORE",
      text: "Mystrium Ore is ERC-20 token has a special smart contracts. In order to enter Mystrios, you must have a minimum of 10 in your wallet. You can get Mystrium Ore by using the base token on the chain you are connected to. Enter Mystrios.com with your wallet and click on the relevant link."
    },
    {
      order: "04",
      title: "WHAT IS REWARD WALLET?",
      text: "Reward Wallet is where reward tokens collected from all games you win are accumulated. You can withdraw these collected tokens to your own wallet at any time or use them to buy Game Tickets."
    },
    {
      order: "05",
      title: "MASTERS OF MYSTRIOS",
      text: "Masters of Mystrios is council that governs the planet . Mystrians, in order to be selected for Masters of Mystrios, must possess at least 0.1% of the total Mystrium in the entire universe, and lock up at least half of what they possess in a safe, never to be used again."
    }
  ]

  let faq = []

  list.forEach((item, index) => {
    faq.push(
      <div key={index} className={Styles["home-faq__item"]}>
        <div className="text-sm">{item.order}</div>
        <div className={Styles["home-faq__item-title"] + " " + "lg:text-2xl"}>{item.title}</div>
        <div className="sm:text-xl text-lg">{item.text}</div>
      </div>
    )
  })

  const containerRef = useRef([])
  const itemRef = useRef([])

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)

    gsap.to(itemRef.current, {
      scrollTrigger: {
        trigger: containerRef.current,
        start: "top top",
        end: "bottom bottom",
        scrub: true
      },
      rotation: 360
    })
  }, [])

  return (
    <div id="playtoearn" ref={containerRef} className={Styles["home-faq"]}>
      <div className="container-fluid">
        <div className="row">
          <div className="xl:col-5 lg:col-6">
            <div className={Styles["home-faq__content"]}>
              <Heading
                top="MULTIPLAYER BLOCKCHAIN PLAY TO EARN GAME"
                title="HOW TO PLAY AND EARN?"
                text="Entering Mystrios is easier than you think. You can easily connect with your Metamask wallet. You start earning every minute you spend."
              />
              <div className={Styles["home-faq__content-image"]}>
                <img ref={itemRef} src="/images/space/planet.webp" alt="hero image" />
              </div>
            </div>
          </div>
          <div className="xl:col-7 lg:col-6">
            <div className={Styles["home-faq__list"]}>{faq}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Faq
