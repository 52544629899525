import Styles from "styles/pages/Home.module.scss"

function Heading({ top, title, text }) {
  return (
    <div className={Styles["home-heading"]}>
      <div className="text-lg sm:mb-0 mb-2">{top}</div>
      <div className="xl:text-4xl sm:text-3xl text-2xl sm:mb-6 mb-4">{title}</div>
      <div className="xl:text-xl sm:text-lg text-md">{text}</div>
    </div>
  )
}

export default Heading
