"use client"
import { useState, useEffect, useRef } from "react"
import Styles from "styles/pages/Home.module.scss"
import Icon from "components/Icon"
import Button from "components/Button"
import Modal from "components/Modal"
import Select from "components/modal/Select"
import Source from "components/modal/Source"
import { gsap } from "gsap/dist/gsap"
import ScrollTrigger from "gsap/ScrollTrigger"

const LightBox = ({ isOpen, onClose, children }) => {

  const parentClassName = isOpen ? `${Styles["home-lightbox"]} ${Styles["show"]}` : Styles["home-lightbox"]

  return (
    <div className={parentClassName}>
      <div className={Styles["home-lightbox__content"]}>
        {children}
      </div>
      <div onClick={onClose} className={Styles["home-lightbox__overlay"]} />
      <button onClick={onClose} className={Styles["home-lightbox__close"]} aria-label="close button">
        <Icon name="close" />
      </button>
    </div>
  );
}
const Hero = (props) => {

  const networkList = [
    {
      text: "Ethereum-Mainnet",
      logo: "/images/markets/1.svg",
      shorty: "eth",
      name: "Ethereum",
      type: "ETH",
      mysValue: "200000"
    },
    {
      text: "Avalanche C-Chain",
      logo: "/images/markets/2.svg",
      shorty: "avax",
      name: "Avalanche",
      type: "AVAX",
      mysValue: "250"
    },
    {
      text: "BNB Smart Chain",
      logo: "/images/markets/3.svg",
      shorty: "bnb",
      name: "BNB",
      type: "BNB",
      mysValue: "250"
    },
    {
      text: "Fantom Network",
      logo: "/images/markets/4.svg",
      shorty: "ftm",
      name: "Fantom",
      type: "FTM",
      mysValue: "250"
    },
    {
      text: "Polygon Network",
      logo: "/images/markets/5.svg",
      shorty: "matic",
      name: "Polygon",
      type: "MATIC",
      mysValue: "250"
    }
  ]

  const [isSwapOpen, setIsSwapOpen] = useState(false)
  const [isSwapMenuOpen, setIsSwapMenuOpen] = useState(false)
  const [isJoinOpen, setIsJoinOpen] = useState(false)

  const openSwapMenu = () => {
    setIsSwapMenuOpen(true)
  }
  const closeSwapMenu = () => {
    setIsSwapMenuOpen(false)
  }
  const openSwap = () => {
    setIsSwapOpen(true)
    setIsSwapMenuOpen(false)
  }
  const closeSwap = () => {
    setIsSwapOpen(false)
  }
  const openJoin = () => {
    setIsJoinOpen(true)
  }
  const closeJoin = () => {
    setIsJoinOpen(false)
  }

  const characterOne = useRef([])
  const characterTwo = useRef([])
  const characterThree = useRef([])
  const line = useRef([])
  const star = useRef([])
  const secondary = useRef([])
  const planet = useRef([])
  const cursorRef = useRef(null)
  const parentRef = useRef(null)
  const hoverItemsRef = useRef([])

  useEffect(() => {

    gsap.registerPlugin(ScrollTrigger)

    gsap.set(characterOne, { transformOrigin: "center" })
    gsap.set(characterTwo, { transformOrigin: "center" })
    gsap.set(characterThree, { transformOrigin: "center" })
    gsap.set(secondary, { transformOrigin: "center" })

    gsap.from(characterTwo.current, {
      duration: 1.5,
      rotation: "+=2",
      translateY: "+= 10px",
      repeat: -1,
      yoyo: true,
      ease: "sine.inOut",
      delay: 0.1
    })
    gsap.from(characterThree.current, {
      duration: 1.5,
      rotation: "+=2",
      translateY: "+= 10px",
      repeat: -1,
      yoyo: true,
      ease: "sine.inOut",
      delay: 0.2
    })
    gsap.from(characterOne.current, {
      duration: 1.5,
      rotation: "+=2",
      translateY: "+= -10px",
      repeat: -1,
      yoyo: true,
      ease: "sine.inOut",
      delay: 0.3
    })
    gsap.from(line.current, {
      duration: 3,
      rotate: -0.4,
      translateY: "+= -4px",
      translateX: "+= 6px",
      repeat: -1,
      yoyo: true,
      ease: "sine.inOut",
      delay: 0.3
    })
    gsap.from(secondary.current, {
      duration: 12,
      rotation: "+=360",
      repeat: -1,
      ease: "linear",
      delay: 0.3
    })
    gsap.from(star.current, {
      duration: 260,
      rotation: "+=360",
      repeat: -1,
      ease: "linear",
      delay: 0.3
    })
    gsap.to(planet.current, {
      duration: 800,
      rotation: "+=360",
      repeat: -1,
      ease: "linear",
      delay: 0.3
    })

    const cursor = cursorRef.current
    const parent = parentRef.current

    gsap.set(cursor, { scale: 1, opacity: 1 })

    parent.addEventListener("mousemove", moveCursor)
    parent.addEventListener("mouseenter", showCursor)
    parent.addEventListener("mouseleave", hideCursor)

    hoverItemsRef.current.forEach((item) => {
      item.addEventListener("mouseenter", hideCursor)
      item.addEventListener("mouseleave", showCursor)
    })

    function moveCursor(e) {
      gsap.to(cursor, { duration: 0.3, left: e.pageX - 110, top: e.pageY - 74 })
    }

    function showCursor() {
      gsap.to(cursor, { duration: 0.3, opacity: 1 })
    }

    function hideCursor() {
      gsap.to(cursor, { duration: 0.3, opacity: 0 })
    }

    return () => {
      parent.removeEventListener("mousemove", moveCursor)
      parent.removeEventListener("mouseenter", showCursor)
      parent.removeEventListener("mouseleave", hideCursor)

      hoverItemsRef.current.forEach((item) => {
        item.removeEventListener("mouseenter", hideCursor)
        item.removeEventListener("mouseleave", showCursor)
      })
    }
  }, [])


  const [isOpenLightBox, setIsOpenLightBox] = useState(false);

  const OpenLightBox = () => {
    setIsOpenLightBox(true);
  };

  const CloseLightBox = () => {
    setIsOpenLightBox(false);
  };

  const [mysInReturn, setMysInReturn] = useState(200000);
  const [mysValue, setMysValue] = useState(200000);
  const [reference, setReference] = useState("1 ETH ≈ 200000 MYSTRIUM");
  const [arrayNumber, setArrayNumber] = useState(0);

  useEffect(() => {
    switch (props.networkId) {
      case 1:
        setMysInReturn(200000);
        setMysValue(200000)
        setReference("1 ETH ≈ 200000 MYSTRIUM")
        setArrayNumber(0)
        break;
      case 43114:
        setMysInReturn(100);
        setMysValue(100)
        setReference("1 AVAX ≈ 100 MYSTRIUM")
        setArrayNumber(1)
        break;
      case 56:
        setMysInReturn(1000);
        setMysValue(1000)
        setReference("1 BNB ≈ 1000 MYSTRIUM")
        setArrayNumber(2)
        break;
      case 250:
        setMysInReturn(2000);
        setMysValue(2000)
        setReference("1 FANTOM ≈ 2000 MYSTRIUM")
        setArrayNumber(3)
        break;
      case 137:
        setMysInReturn(4000);
        setMysValue(4000)
        setReference("1 MATIC ≈ 4000 MYSTRIUM")
        setArrayNumber(4)
        break;
      default:
        setMysInReturn(200000);
        setMysValue(200000)
        setReference("1 ETH ≈ 200000 MYSTRIUM")
        setArrayNumber(1)
        break;
    }
  }, [props])

  const handleOptionChange = (optionValue, mysValue) => {
    setMysInReturn(mysValue);
    setMysValue(mysValue)

    const reference = `1 ${optionValue.toUpperCase()} ≈ ${mysValue} MYSTRIUM`
    setReference(reference)
  };

  const handleInputChange = (inputValue) => {
    const result = inputValue * mysValue
    setMysInReturn(result)
  };

  return (
    <>
      <div ref={parentRef} className={Styles["home-hero"]}>
        <div onClick={OpenLightBox} className={Styles["home-hero__link"]} ref={cursorRef}>
          <img
            className={Styles["home-hero__link-icon"]}
            src="/images/home/hero/youtube-circle.svg"
            alt="youtube circle"
          />
          <img className={Styles["home-hero__link-texts"]} src="/images/home/hero/texts.svg" alt="texts" />
        </div>
        <div className="container-fluid relative">
          <div className={Styles["home-hero__container"]}>
            <div className={Styles["home-hero__heading"]}>
              <div ref={(el) => (hoverItemsRef.current[0] = el)} className="overflow-hidden">
                <div className={Styles["home-hero__heading-title"]}>PLANET MYSTRIOS</div>
              </div>
              <div ref={(el) => (hoverItemsRef.current[1] = el)} className={Styles["home-hero__heading-text"]}>
                Mystrios is a part of <a href="https://skale.space/ecosystem" target="_blank">SKALEverse Ecosystem</a>, SKALE Network is brings speed, decentralization and security
                to Mystrios. You will not pay gas fee for any transaction you will make in Mystrios. Play to Earn with
                Zero Gas Fees
              </div>
            </div>
            <div className={Styles["home-hero__image"]}>
              <div className={Styles["home-hero__image-planet"]}>
                <img ref={planet} src="/images/space/planet.webp" alt="planet" />
              </div>
              <div className={Styles["home-hero__image-star"]}>
                <img
                  ref={star}
                  src="/images/space/star.webp"
                  style={{
                    "--width": "100%",
                    "--height": "100%",
                    "--bottom": "-28px",
                    "--right": "-20px",
                    "--m-width": "100%",
                    "--m-height": "100%",
                    "--m-bottom": "-8px",
                    "--m-right": "-10px",
                    zIndex: "0"
                  }}
                  alt="star"
                />
                <img
                  ref={secondary}
                  src="/images/space/secondary.webp"
                  style={{
                    "--width": "42px",
                    "--height": "42px",
                    "--top": "152px",
                    "--left": "156px",
                    "--m-width": "14px",
                    "--m-height": "14px",
                    "--m-top": "58px",
                    "--m-left": "60px"
                  }}
                  alt="secondary"
                />
                <img
                  src="/images/home/hero/scale.webp"
                  style={{
                    "--width": "132px",
                    "--height": "35px",
                    "--bottom": "280px",
                    "--right": "240px",
                    "--m-width": "44px",
                    "--m-height": "12px",
                    "--m-bottom": "96px",
                    "--m-right": "80px"
                  }}
                  alt="scale"
                />
              </div>
              <div className={Styles["home-hero__image-line"]}>
                <img
                  ref={characterOne}
                  src="/images/characters/4.svg"
                  style={{
                    "--width": "92px",
                    "--height": "92px",
                    "--top": "212px",
                    "--left": "538px",
                    "--m-width": "58px",
                    "--m-height": "58px",
                    "--m-top": "36px",
                    "--m-right": "112px"
                  }}
                  alt="character"
                />
                <img
                  ref={characterTwo}
                  src="/images/characters/2.svg"
                  style={{
                    "--width": "120px",
                    "--height": "122px",
                    "--top": "277px",
                    "--left": "216px",
                    "--m-width": "77px",
                    "--m-height": "77px",
                    "--m-top": "50px",
                    "--m-left": "92px"
                  }}
                  alt="character"
                />
                <img
                  ref={characterThree}
                  src="/images/characters/1.svg"
                  style={{
                    "--width": "154px",
                    "--height": "154px",
                    "--bottom": "154px",
                    "--right": "340px",
                    "--m-width": "97px",
                    "--m-height": "97px",
                    "--m-bottom": "15px",
                    "--m-right": "92px",
                    zIndex: "1"
                  }}
                  alt="character"
                />
                <img
                  ref={line}
                  src="/images/home/hero/line.webp"
                  style={{
                    "--width": "100%",
                    "--height": "100%",
                    "--top": "0",
                    "--left": "0",
                    "--m-width": "100%",
                    "--m-height": "100%",
                    "--m-top": "0",
                    "--m-left": "0",
                    zIndex: "0"
                  }}
                  alt="line"
                />
              </div>
            </div>
            <div ref={(el) => (hoverItemsRef.current[2] = el)} className={Styles["home-hero__actions"]}>
              <Button onClick={props.networkStatus > 0 ? openSwap : openSwapMenu} icon="right-arrow" color="white" size="xl">
                SWAP MYSTRIUM ORE
              </Button>
              <Button onClick={openJoin} icon="right-arrow" color="white" size="xl">
                TRANSFER TO SKALE
              </Button>
            </div>
          </div>
        </div>
        <div className={Styles["home-hero__background"]}>
          <img src="/images/background.svg" alt="background" />
        </div>
      </div>
      <Modal
        isOpen={isSwapMenuOpen}
        onClose={closeSwapMenu}
        heading={
          <div className={Styles["home-modal__heading"]}>
            <div className={Styles["home-modal__heading-icon"]}>
              <Icon name="synchronize-arrows" />
            </div>
            <div className={Styles["home-modal__heading-background"]}>
              <img src="/images/home/hero/modal/1.svg" height="94" width="94" alt="background" />
            </div>
          </div>
        }
        content={
          <div className={Styles["home-modal__content"]}>
            <div className="sm:text-2xl text-xl font-bold">SWAP TO MYSTRUIM ORE</div>
            <div className="sm:text-xl text-lg">
              You should connect with your Metamask and change your network to a valid network.<br/>Mystrium Ore Swap
              supported networks are Ethereum, AVAX, BNB Chain, Fantom, Polygon
            </div>
          </div>
        }
        footer={
          <div className={Styles["home-modal__markets"]}>
            {Array.from({ length: 5 }, (_, index) => (
              <button className={Styles["home-modal__markets-item"]} key={index}>
                <img src={`/images/markets/${index + 1}.svg`} />
              </button>
            ))}
          </div>
        }
      />
      <Modal
        isOpen={isJoinOpen}
        onClose={closeJoin}
        heading={
          <div className={Styles["home-modal__heading"]}>
            <div className={Styles["home-modal__heading-icon"]}>
              <Icon name="cursor" />
            </div>
            <div className={Styles["home-modal__heading-background"]}>
              <img src="/images/home/hero/modal/2.svg" height="94" width="94" alt="background" />
            </div>
          </div>
        }
        content={
          <div className={Styles["home-modal__content"]}>
            <div className="sm:text-2xl text-xl font-bold">PLAY MYSTRIOS</div>
            <div className="sm:text-xl text-lg">Mysterios is playing only on SKALE Network</div>
          </div>
        }
        footer={
          <div className={Styles["home-modal__actions"]}>
            <Button href="/" color="secondary" size="xl" icon="right-arrow">
              CONNECT SKALE
            </Button>
          </div>
        }
      />
      <Modal
        isOpen={isSwapOpen}
        onClose={closeSwap}
        heading={<div className={Styles["home-modal__title"]}>SWAP TO MYSTRUIM ORE</div>}
        content={
          <div className={Styles["home-modal__swap"]}>
            <div className={Styles["home-modal__swap-content"]}>
              <Source logo={`/images/markets/${arrayNumber + 1}.svg`} shorty={networkList[arrayNumber].type} name={networkList[arrayNumber].name} value="1.0" onInputChange={handleInputChange} />
              <span className={Styles["home-modal__swap-icon"]}>
                <Icon name="swap" />
              </span>
              <Source logo="/images/source/mys.svg" shorty="MYSTRIUM" name="MYSTRIUM ORE" value={mysInReturn} type="" wallet={true} />
            </div>
            <span className={Styles["home-modal__swap-reference"]}>Reference : {reference}</span>
          </div>
        }
        footer={
          <div className={Styles["home-modal__actions"]}>
            <Button href="/" color="secondary" size="xl" icon="right-arrow">
              BUY MYSTRIUM
            </Button>
          </div>
        }
      />
      <LightBox isOpen={isOpenLightBox} onClose={CloseLightBox}>
        <iframe width="1904" height="778" src="https://www.youtube.com/embed/097TRvFbHWE?autoplay=1&mute=1&rel=0" title="Mystrios Planet"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen></iframe>
      </LightBox>
    </>
  )
}

export default Hero
