"use client"
import { useEffect, useRef } from "react"
import { gsap } from "gsap/dist/gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
import Styles from "styles/pages/Home.module.scss"
import Icon from "components/Icon"
import Heading from "components/home/Heading"

const Join = () => {
  const containerRefs = useRef([])
  const itemRefs = useRef([])

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)

    gsap.set(itemRefs.current, { transformOrigin: "center" })

    itemRefs.current.forEach((item, index) => {
      let animation

      if (index === 0) {
        animation = gsap.to(item, {
          rotate: 360,
          scale: 0.5,
          opacity: 0,
          duration: 1.5,
          ease: "power2",
          paused: true,
          onComplete: () => {
            item.animation.reverse()
          }
        })
      } else if (index === 1) {
        animation = gsap.to(item, {
          rotate: 360,
          duration: 1.5,
          ease: "power2",
          paused: true
        })
      } else if (index === 2) {
        animation = gsap.to(item, {
          x: 70,
          opacity: 0,
          duration: 1,
          ease: "power2",
          paused: true,
          onComplete: () => {
            item.animation.reverse()
          }
        })
      } else if (index === 3) {
        animation = gsap.to(item, {
          rotate: 45,
          ease: "elastic",
          duration: 1.5,
          paused: true,
          onComplete: () => {
            const reverseAnimation = gsap.to(item, {
              rotate: 0,
              ease: "linear",
              duration: 0.25,
              paused: true
            })

            reverseAnimation.play()
          }
        })
      }

      item.animation = animation

      const container = containerRefs.current[index]
      container.addEventListener("mouseenter", handleMouseEnter)
    })
  }, [])

  const handleMouseEnter = (e) => {
    const container = e.target
    const index = containerRefs.current.indexOf(container)
    const item = itemRefs.current[index]

    if (item.animation.paused()) {
      item.animation.play()
    } else {
      item.animation.pause()
      item.animation.restart()
    }
  }

  const list = [
    {
      icon: "wallet",
      title: "Connect Your Wallet",
      text: "Open mystrios.com on your Metamask browser on mobile or connect with Metamask add-on with your pc.",
      background: "/images/home/join/1.svg"
    },
    {
      icon: "synchronize-arrows",
      title: "Swap Some Mystrios Ore",
      text: "Connect on supported networks as Ethereum Mainnet, Avalanche Mainnet, BNB Smart Chain, Fantom, Polygon Network and click Swap Mystrium button on homepage.",
      background: "/images/home/join/2.svg"
    },
    {
      icon: "cursor",
      title: "Join Mystrios",
      text: "Change your network to SKALE Network Nebula Gaming Hub and join Mystrios.",
      background: "/images/home/join/3.svg"
    },
    {
      icon: "wand",
      title: "Create Your NFTs",
      text: "Create your NFTs and enjoy your time in our planet Mystrios, you can earn lots of things in your new adventure.",
      background: "/images/home/join/4.svg"
    }
  ]

  let features = []

  list.forEach((item, index) => {
    features.push(
      <div key={index} ref={(el) => (containerRefs.current[index] = el)} className={Styles["home-join__item"]}>
        <div className={Styles["home-join__item-icon"]}>
          <span ref={(el) => (itemRefs.current[index] = el)}>
            <Icon name={item.icon} />
          </span>
          <img src={item.background} alt="background" />
        </div>
        <div className={Styles["home-join__item-content"]}>
          <div className="lg:text-2xl text-xl font-bold">{item.title}</div>
          <div className="lg:text-xl text-lg">{item.text}</div>
        </div>
      </div>
    )
  })

  return (
    <div id="faq" className={Styles["home-join"]}>
      <div className="container-fluid">
        <div className="row gy-6">
          <div className="2xl:col-5 lg:col-6">
            <Heading
              top="ZERO GAS FEES FOR GAMERS"
              title="HOW TO JOIN MYSTRIOS"
              text="Entering Mystrios is easier than you think. You can easily connect with your Metamask wallet. You star earning every minute you spend."
            />
          </div>
          <div className="lg:col-6 2xl:offset-1">
            <div className={Styles["home-join__list"]}>{features}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Join
