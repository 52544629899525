"use client"
import { useState, useEffect } from "react"
import Styles from "styles/components/Modal.module.scss"
import Icon from "components/Icon"

const Modal = ({ isOpen, onClose, onOpen, heading, content, footer }) => {
  const closeModal = () => {
    onClose()
  }

  useEffect(() => {
    if (isOpen && onOpen) {
      onOpen()
    }
  }, [isOpen, onOpen])

  const parentClassName = isOpen ? `${Styles.modal} ${Styles.show}` : Styles.modal

  return (
    <>
      <div className={parentClassName}>
        <div className={Styles["modal-dialog"]}>
          <div className={Styles["modal-heading"]}>
            <div className={Styles["modal-heading__content"]}>{heading}</div>
            <button onClick={closeModal} className={Styles["modal-heading__button"]} aria-label="close button">
              <Icon name="close" />
            </button>
          </div>
          {content && <div className={Styles["modal-content"]}>{content}</div>}
          {footer && <div className={Styles["modal-footer"]}>{footer}</div>}
        </div>
        <div onClick={closeModal} className={Styles["modal-overlay"]}></div>
      </div>
    </>
  )
}

export default Modal
