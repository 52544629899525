"use client"
import { useState } from "react"
import Styles from "styles/components/Modal.module.scss"
const Source = ({ selectable }) => {
  const [selectedItem, setSelectedItem] = useState(0)
  const list = [
    {
      image: "/images/characters/1.svg",
      active: true
    },
    {
      image: "/images/characters/2.svg",
      active: true
    },
    {
      image: "/images/characters/3.svg",
      active: true
    },
    {
      image: "/images/characters/4.svg",
      active: true
    },
    {
      image: "/images/characters/1.svg",
      active: false
    },
    {
      image: "/images/characters/1.svg",
      active: false
    },
    {
      image: "/images/characters/2.svg",
      active: false
    },
    {
      image: "/images/characters/3.svg",
      active: false
    },
    {
      image: "/images/characters/4.svg",
      active: false
    },
    {
      image: "/images/characters/3.svg",
      active: false
    }
  ]

  const handleItemClick = (index) => {
    if (selectable) {
      setSelectedItem(index)
    }
  }

  const characters = list.map((item, index) => (
    <div
      key={index}
      className={`${Styles["modal-characters__item"]} ${item.active ? Styles.active : ""} ${
        item.active && selectedItem === index ? Styles.selected : ""
      }`}
      onClick={() => item.active && handleItemClick(index)}
    >
      {item.active && <img src={item.image} alt="character image" />}
      {!item.active && (
        <div
          className={Styles["modal-characters__item-placeholder"]}
          style={{
            WebkitMask: `url(${item.image})`,
            WebkitMaskSize: "auto 56%",
            WebkitMaskPosition: "center",
            WebkitMaskRepeat: "no-repeat",
            mask: `url(${item.image})`,
            maskSize: "auto 56%",
            maskPosition: "center",
            maskRepeat: "no-repeat"
          }}
        ></div>
      )}
      {selectable && <div className={Styles["modal-characters__item-effect"]}></div>}
    </div>
  ))

  return (
    <div className={`${Styles["modal-characters"]} ${selectable ? Styles["modal-characters--selectable"] : ""}`}>
      {characters}
    </div>
  )
}

export default Source
