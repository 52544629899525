import { useContext } from 'react';
import Styles from "styles/pages/Home.module.scss"
import Hero from "components/home/Hero"
import Bar from "./components/home/Bar"
import Promotion from "./components/home/Promotion"
import Join from "./components/home/Join"
import Faq from "./components/home/Faq"
import Earn from "./components/home/Earn"
import { WalletContext } from './WalletContext';

import { Layouts } from "components/common/Layouts"

const App = (props) => {

  const [wallet, networkId, networkStatus, isSkale, shortName, mystOreAdd, ethPrice] = useContext(WalletContext)

  return (
    <Layouts.Landing wallet={wallet} networkId={networkId} networkStatus={networkStatus} isSkale={isSkale} shortName={shortName} mystOreAdd={mystOreAdd} ethPrice={ethPrice}>
      <div className={Styles.home}>
      <Hero wallet={wallet} networkId={networkId} networkStatus={networkStatus} isSkale={isSkale} shortName={shortName} mystOreAdd={mystOreAdd} ethPrice={ethPrice} />
        <Bar />
        <div className={Styles.home__content}>
          <Promotion />
          <Join />
          <Faq />
          <Earn />
        </div>
      </div>
    </Layouts.Landing>
  )
}

export default App
