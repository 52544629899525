"use client"
import { useState, useEffect } from "react"
import "alertifyjs/build/css/alertify.css"
import Styles from "styles/pages/Game.module.scss"
import Header from "components/layout/Header"
import Button from "components/Button"
import Characters from "components/modal/Characters"
import Modal from "components/Modal"

const Card = ({ image, title, badge, badgeColor, collect, collectAction, disabled }) => {
  return (
    <div className={Styles["game-card"]}>
      <div className={Styles["game-card__image"]}>
        <img src={image} alt={title} />
      </div>
      <div className={Styles["game-card__content"]}>
        <div className={Styles["game-card__content-title"]}>{title}</div>
        {badge && (
          <div
            className={`${Styles["game-card__content-badge"]} ${
              badgeColor ? Styles[`game-card__content-badge--${badgeColor}`] : ""
            }`}
          >
            {badge}
          </div>
        )}
      </div>
      {collect && (
        <button onClick={collectAction} disabled={disabled} className={Styles["game-card__button"]}>
          COLLECT
        </button>
      )}
    </div>
  )
}

const Reward = ({ logo, name, value }) => {
  return (
    <div className={Styles["game-reward"]}>
      <div className={Styles["game-reward__logo"]}>
        <img src={logo} alt={name} />
      </div>
      <div className={Styles["game-reward__name"]}>{name}</div>
      <div className={Styles["game-reward__value"]}>{value}</div>
      <div className={Styles["game-reward__action"]}>
        <Button color="green">CLAIM</Button>
      </div>
    </div>
  )
}

const GameLayout = ({ children }) => {
  const [isStartSelectOpen, setIsStartSelectOpen] = useState(true)
  const [isSelectOpen, setIsSelectOpen] = useState(false)
  const [isRewardOpen, setIsRewardOpen] = useState(false)

  const closeStartSelect = () => {
    setIsStartSelectOpen(false)
  }
  const openSelect = () => {
    setIsSelectOpen(true)
  }
  const closeSelect = () => {
    setIsSelectOpen(false)
  }
  const openReward = () => {
    setIsRewardOpen(true)
  }
  const closeReward = () => {
    setIsRewardOpen(false)
  }

  useEffect(() => {
    import("alertifyjs/build/alertify.min.js")
      .then((alertify) => {
        alertify.message("You can move with the arrow keys of your keyboard.")
        alertify.success("You are connected... Your random avatar is created!")
        alertify.error("Your internet seems to be down. Please check your network settings.")
        alertify.warning("You earn 10 Mystrium Ore")
      })
      .catch((error) => {
        console.log("Alertify.js yüklenirken bir hata oluştu:", error)
      })
  }, [])

  return (
    <>
      <div className={Styles["game-heading"]}>
        <div className="container-fluid">
          <div className="row xl:gy-4 gy-6 lg:gx-10 md:gx-6 gx-0">
            <div className="2xl:col-8 md:col-10 col-9 order-1">
              <Header game={true} />
            </div>
            <div className="2xl:col-3 xl:col-10 2xl:order-2 order-3">
              <div className="flex 2xl:flex-col lg:gap-4 gap-1">
                <div className={Styles["game-menu"]}>
                  <Card image="/images/game/icons/heart.webp" title="DEF" badge="2" />
                  <Card image="/images/game/icons/gun.webp" title="atx" badge="NO" badgeColor="red" />
                  <Card image="/images/game/icons/bag.webp" title="FLY" badge="yes" badgeColor="green" />
                </div>
                <div className={Styles["game-menu"]}>
                  <Card
                    image="/images/game/icons/ore.webp"
                    title="MYSTRIUM"
                    badge="40"
                    collect={true}
                    collectAction={openReward}
                  />
                  <Card
                    image="/images/game/icons/gift.webp"
                    title="MYSTRIUS BOX"
                    badge="0"
                    collect={true}
                    collectAction={openReward}
                    disabled={true}
                  />
                </div>
              </div>
            </div>
            <div className="2xl:col-1 md:col-2 col-3 2xl:order-3 order-2">
              <div className={`${Styles["game-menu"]} 2xl:!w-full md:!w-24 !w-16 ml-auto`}>
                <button onClick={openSelect} className={Styles["game-menu__profile"]}>
                  <span className={Styles["game-menu__profile-image"]}>
                    <img src="/images/characters/3.svg" alt="character" />
                  </span>{" "}
                  <span className={Styles["game-menu__profile-title"]}>Select profile</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <main>{children}</main>
      <div className={Styles["game-footer"]}>
        <div className="container-fluid">
          <form action="#" className={Styles["game-footer__form"]}>
            <label className={Styles["game-footer__entry"]}>
              <input placeholder="Type a message here" type="text" />
              <span>80 / 120</span>
            </label>
            <Button type="submit" size="sm" icon="paper-plane" color="green" reverse={true} aria-label="submit button">
              <span className="md:block hidden">SEND</span>
            </Button>
          </form>
        </div>
      </div>
      <Modal
        isOpen={isStartSelectOpen}
        onClose={closeStartSelect}
        heading={<div className={Styles["game-modal__title"]}>character LIST</div>}
        content={<Characters selectable={true} />}
        footer={
          <div className={Styles["game-modal__actions"]}>
            <Button onClick={closeStartSelect} icon="right-arrow" color="secondary" size="xl">
              PLAY GAME
            </Button>
            <Button onClick={closeStartSelect} icon="right-arrow" color="outline-secondary" size="xl">
              RANDOM CHARACTER
            </Button>
          </div>
        }
      />
      <Modal
        isOpen={isSelectOpen}
        onClose={closeSelect}
        heading={<div className={Styles["game-modal__title"]}>character LIST</div>}
        content={<Characters selectable={true} />}
        footer={
          <div className={Styles["game-modal__actions"]}>
            <Button onClick={closeSelect} icon="right-arrow" color="secondary" size="xl">
              continue game
            </Button>
          </div>
        }
      />
      <Modal
        isOpen={isRewardOpen}
        onClose={closeReward}
        heading={<div className={Styles["game-modal__title"]}>REWARD WALLET</div>}
        content={
          <div className={Styles["game-modal__list"]}>
            <Reward logo="/images/source/mys.svg" name="MYSTRIUM ORE" value="0.001" />
            <Reward logo="/images/source/box.svg" name="MYSTRIUM box" value="10 box" />
          </div>
        }
      />
    </>
  )
}
export default GameLayout
