"use client"
import React, { useState } from "react"
import Styles from "styles/components/Cookie.module.scss"
import Icon from "components/Icon"
import Button from "components/Button"

const Cookie = () => {
  const [close, setClose] = useState(false)

  const closeCookie = () => {
    setClose(true)
  }

  const parentClassName = close ? `${Styles.cookie} ${Styles.close}` : Styles.cookie

  return (
    <>
      <div className={parentClassName}>
        <div className={Styles.cookie__modal}>
          <div className={Styles.cookie__content}>
            <div className={Styles.cookie__heading}>
              <span className={Styles["cookie__heading-title"]}>THIS WEBSITE USES COKIE</span>
              <button onClick={closeCookie} className={Styles["cookie__heading-button"]} aria-label="close button">
                <Icon name="close" />
              </button>
            </div>
            <span className={Styles.cookie__text}>
              This website uses cookies to improve user experience. By using our website you consent to all cookies in
              accordance with our Cookie Policy.
            </span>
            <a href="/" className="Read more">
              Read more
            </a>
          </div>
          <div className={Styles.cookie__actions}>
            <Button onClick={closeCookie}>ACCEPT</Button>
            <Button onClick={closeCookie} color="white">
              DECLINE
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}

export default Cookie
