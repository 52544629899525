"use client"
import { useEffect, useRef } from "react"
import { gsap } from "gsap/dist/gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
import Styles from "styles/pages/Home.module.scss"
import Heading from "components/home/Heading"

const Promotion = () => {
  const list = [
    {
      character: "/images/characters/1.svg",
      background: "/images/background/1.svg"
    },
    {
      character: "/images/characters/2.svg",
      background: "/images/background/2.svg"
    },
    {
      character: "/images/characters/3.svg",
      background: "/images/background/3.svg"
    },
    {
      character: "/images/characters/4.svg",
      background: "/images/background/4.svg"
    }
  ]

  let cardList = []

  list.forEach((item, index) => {
    cardList.push(
      <div key={index} className="col-6">
        <div className={Styles["home-promotion__item"]}>
          <img
            ref={(el) => (itemRefs.current[index] = el)}
            className={Styles["home-promotion__item-character"]}
            src={item.character}
            alt="character"
          />
          <img className={Styles["home-promotion__item-background"]} src={item.background} alt="background" />
        </div>
      </div>
    )
  })

  const itemRefs = useRef([])

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)

    gsap.set(itemRefs.current, { transformOrigin: "center",  translateX: "-300px", translateY: "300px" })

    itemRefs.current.forEach((item, index) => {
      gsap.to(item, {
        scrollTrigger: {
          trigger: item,
          toggleActions: "restart none none none"
        },
        translateX: "+= 300px",
        translateY: "+= -300px",
        duration: 1.5,
        ease: "power2",
        delay: 0.2 * index, // Artan gecikme süresi
        onComplete: () => {
          gsap.to(item, {
            duration: 1.5,
            rotate: -0.5,
            translateY: "+= -10px",
            repeat: -1,
            yoyo: true,
            ease: "sine.inOut",
            delay: 0.2
          })
        }
      })
    })
  }, [])

  return (
    <div id="mystrios" className={Styles["home-promotion"]}>
      <div className="container-fluid">
        <div className={Styles["home-promotion__list"] + " " + "row gy-6"}>
          <div className="xl:col-5 md:col-6">
            <Heading
              top="BROWSER BASED WEB3 GAME"
              title="MYSTRIOS"
              text="The planet Mystrios is a unique world unlike any other in the universe. It is located far away from
                Earth in an unknown region, and only one race called the Mystrians inhabits this planet. The most
                important resource on the planet is a special type of ore called Mystrium. This ore is so valuable that
                it cannot be compared to any other mineral found on other planets. Mystrios planet is not just a
                commercial hub but also an unexplored world filled with countless adventure-filled places and mysterious
                secrets waiting to be discovered."
            />
          </div>
          <div className="md:col-6 xl:offset-1">
            <div className="row xl:g-10 g-6">{cardList}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Promotion
